import parse, { processNodes } from 'react-html-parser';
import styled from 'styled-components';

function addTdFactorial(node, arr) {
  if (!node.next || node.next.name !== 'td') return arr;
  arr.push(
    <td key={`td${arr.length}`}>
      {processNodes(node.next.children, transform)}
    </td>
  );
  return addTdFactorial(node.next, arr);
}
function transform(node, index) {
  // if img has no alt attr
  if (node.type === 'tag' && node.name === 'img' && !node.attribs.alt) {
    node.attribs.alt = '';
  }
  // if td is not wrap with tr
  if (node.name === 'td' && node.parent.name !== 'tr') {
    if (node.prev && node.prev.name === 'td')
      return <React.Fragment key={`fragment${index}`}></React.Fragment>;
    const additional_td = addTdFactorial(node, []);
    return (
      <tr key={`tr${index}`}>
        <td>{processNodes(node.children, transform)}</td>
        {additional_td}
      </tr>
    );
  }
  // if table has no tbody
  if (
    node.type === 'tag' &&
    node.name === 'table' &&
    node.children[0].name === 'tr'
  ) {
    const Table = styled.table`
      ${node.attribs.style ? node.attribs.style : null}
    `;
    return (
      <Table key={`table${index}`} className={node.attribs.class}>
        <tbody>{processNodes(node.children, transform)}</tbody>
      </Table>
    );
  }
}

const options = {
  decodeEntities: true,
  transform,
};

export default htmlElement => {
  if (typeof htmlElement !== 'string') {
    return htmlElement;
  }
  return parse(htmlElement.replace(/(?:\r\n|\r|\n)/g, '<br>'), options);
};
